import { GET_CONTACTS } from 'actions/contacts'
import { AnyAction } from 'redux'
import { ContactObj } from 'objects/contacts'

export const initialState: ContactObj = {
  secondaryContact: null
}

const Contacts = (state = initialState, action: AnyAction) => {
  const newState = JSON.parse(JSON.stringify(state)) as any

  switch (action.type) {
    case `${GET_CONTACTS}_FULFILLED`:
      const data = action?.payload?.data
      newState.secondaryContact = !!data?.secondary?.id
      return newState
    default:
      return state
  }
}

export default Contacts
