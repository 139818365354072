export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  return parts.length === 2
    ? parts
        .pop()
        .split(';')
        .shift()
    : ''
}

export const deleteCookie = (name: string) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export const MAINTENANCE_MODE =
  window?._env_.REACT_APP_MAINTENANCE_MODE === 'true'
