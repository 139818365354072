import { LOCATION_CHANGE } from 'connected-react-router'

const navigationToTop = (store: any) => (next: any) => (action: any) => {
  if (action.type === LOCATION_CHANGE) {
    window.scrollTo(0, 0)
  }
  return next(action)
}

export default navigationToTop
