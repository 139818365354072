import React, { CSSProperties } from 'react'

type DisclosureProps = {
  style?: CSSProperties
}

export default function disclosure({ style }: DisclosureProps) {
  return (
    <div className='disclosure__w' style={style}>
      <p className='disclosure__text'>
        ©{new Date().getFullYear()} United Capital Financial Advisors, LLC
        (“United Capital”). All Rights Reserved. | FinLife Partners is a d/b/a
        of United Capital Financial Advisors, LLC
      </p>
      <p className='disclosure__links-w'>
        <a
          className='disclosure__privacy-policy'
          href='https://www.unitedcapitalwealth.com/privacy-policy/'
          target='_blank'
          rel='noopener noreferrer'>
          Privacy Policy |{' '}
        </a>
        <a
          className='disclosure__site-disclosures'
          href='https://www.unitedcapitalwealth.com/disclosures/'
          target='_blank'
          rel='noopener noreferrer'>
          Site Disclosures
        </a>
      </p>
    </div>
  )
}
