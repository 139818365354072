import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { CardCategoryType } from 'objects/card'
import { ClientCardObj } from 'objects/clientCard'
import React, { Component } from 'react'

export interface FiveCardMobileCardProps {
  index: number
  card: ClientCardObj
  pickedCards: ClientCardObj[]
  expanded: boolean
  addPick(
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    card: ClientCardObj
  ): void
  toggleExpandCard(cardType: CardCategoryType): void
}

class FiveCardMobileCard extends Component<FiveCardMobileCardProps> {
  addPick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const { card, addPick } = this.props
    addPick(e, card)
  }
  toggleExpandCard = () => {
    const { card, toggleExpandCard } = this.props
    toggleExpandCard(card.card.category)
  }
  render() {
    const { index, card, expanded, pickedCards } = this.props
    const clientCard = card.card
    const active =
      pickedCards.filter(
        (pickedCard) =>
          pickedCard && pickedCard.card && pickedCard.card.id === card.card.id
      ).length > 0
    return (
      <div
        onClick={this.toggleExpandCard}
        style={{
          marginBottom: expanded ? '-37px' : '-115px',
          zIndex: 5 - index
        }}
        className={
          active
            ? 'five-card-mobile-table__card five-card-mobile-table__card--active'
            : 'five-card-mobile-table__card '
        }>
        <Card category={clientCard.category} title={clientCard.title} />
        <span
          onClick={this.addPick}
          className={
            active
              ? 'five-card-mobile-table__toggle five-card-mobile-table__toggle--protection five-card-mobile-table__toggle--protection-active'
              : 'five-card-mobile-table__toggle five-card-mobile-table__toggle--protection'
          }
        />
      </div>
    )
  }
}

export default FiveCardMobileCard
