import { SET_EXERCISE_ID_AND_CLIENT_ID } from 'actions/auth'
import { GET_CLIENT_CARDS } from 'actions/clientCard'
import { AnyAction } from 'redux'

export interface AuthenticationInterface {
  exerciseId: string
  clientId: string
  authFailed: boolean
}

const initialState: AuthenticationInterface = {
  exerciseId: null,
  clientId: null,
  authFailed: false
}

const AuthenticationFailed = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_EXERCISE_ID_AND_CLIENT_ID:
      return {
        ...state,
        exerciseId: action.payload.exerciseId,
        clientId: action.payload.clientId
      }
    case `${GET_CLIENT_CARDS}_FULFILLED`:
      return { ...state, authFailed: false }
    case `${GET_CLIENT_CARDS}_REJECTED`:
      return { ...state, authFailed: true }
    default:
      return state
  }
}

export default AuthenticationFailed
