import { AppHeader } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import HcLogo from 'assets/images/hcLogo.png'
import { CSSProperties, ReactNode, useEffect, useState } from 'react'
import { GlobalState } from 'reducers'
import { connect } from 'react-redux'
import { AppDispatch } from '../../store'
import { getClient } from 'actions/client'

export interface HeaderProps {
  dispatch: AppDispatch
  progressBarStyles: CSSProperties
  clientName: string
  middleHeader?: ReactNode
  rightHeader?: ReactNode
}

export interface HeaderState {
  deviceType: EDeviceType
}

export interface OverrideStyleInterface {
  wrapper: CSSProperties
  titleWrapper: CSSProperties
  hhName: CSSProperties
  logo: CSSProperties
}

enum EDeviceType {
  mobile,
  desktop,
  tablet
}

const getStyles = (deviceType: EDeviceType) => {
  // default styles cover mobile screens
  const overrideStyle: OverrideStyleInterface = {
    wrapper: {
      padding: '8px',
      justifyContent: 'center'
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%'
    },
    hhName: { display: 'none' },
    logo: {
      maxWidth: '175px',
      borderLeft: 'none',
      margin: 0
    }
  }
  // overwrite depending on screen size
  switch (deviceType) {
    case EDeviceType.tablet: {
      overrideStyle.wrapper = {
        padding: '15px',
        justifyContent: 'center'
      }
      overrideStyle.hhName = {}
      break
    }
    case EDeviceType.desktop: {
      overrideStyle.wrapper = {
        justifyContent: 'center'
      }
      overrideStyle.hhName = {}
      overrideStyle.logo = {
        maxWidth: '205px'
      }
      break
    }
  }
  return overrideStyle
}

const Header = (props: HeaderProps) => {
  const { clientName, middleHeader, rightHeader } = props
  const [deviceType, setDeviceType] = useState<HeaderState['deviceType']>(null)

  const updateWindowDimensions = () => {
    if (window.innerWidth < 750) {
      setDeviceType(EDeviceType.mobile)
    } else if (window.innerWidth > 1042) {
      setDeviceType(EDeviceType.desktop)
    } else {
      setDeviceType(EDeviceType.tablet)
    }
  }
  useEffect(() => {
    const { dispatch, clientName } = props
    if (!clientName) {
      dispatch(getClient())
    }
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  const overrideStyle = getStyles(deviceType)

  return (
    <AppHeader
      logoSrc={HcLogo}
      logoAlt='HonestConversations'
      logoStyles={overrideStyle.logo}
      hhName={deviceType === EDeviceType.mobile ? null : clientName}
      hhNameStyles={overrideStyle.hhName}
      progressBarStyles={{ width: '100%' }}
      wrapperStyles={overrideStyle.wrapper}
      rightHeader={rightHeader ? rightHeader : null}
      middleHeader={middleHeader ? middleHeader : null}
      titleWrapperStyles={overrideStyle.titleWrapper}
    />
  )
}

const mapStateToProps = (store: GlobalState) => {
  const client = store.client
  return {
    clientName: client.name
  }
}

export default connect(mapStateToProps)(Header)
