import React from 'react'
import InstructionsModal from 'components/InstructionsModal'
import Pick5Web from 'assets/images/five_card_pick/webtutorial-pick5.gif'
import Pick5Still from 'assets/images/five_card_pick/tutorial-02-step-1_still.jpg'

export interface FiveCardPickInstructionsProps {
  onDismiss(): void
}
class FiveCardPickInstructions extends React.Component<
  FiveCardPickInstructionsProps
> {
  render() {
    const { onDismiss } = this.props
    const instructions = {
      web: Pick5Web,
      still: Pick5Still
    }
    return (
      <InstructionsModal
        onDismiss={onDismiss}
        instructions={instructions}
        instructionGif='five'
        web={true}
      />
    )
  }
}

export default FiveCardPickInstructions
