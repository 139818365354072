import { history } from 'store'
import { deleteCookie } from 'helpers'

export const authentication = (_store: any) => (next: any) => async (
  action: any
) => {
  if (action.type.includes('_REJECTED')) {
    if (action.payload) {
      if (
        action.payload.response &&
        action.payload.response.status === 401 &&
        history.location.pathname !== '/begin'
      ) {
        deleteCookie('acToken')
        deleteCookie('gcToken')
        window.location.replace(window._env_.REACT_APP_GUIDECENTER_URL)
      }
    }
  }
  return next(action)
}
