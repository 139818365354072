import {
  Button,
  Tile
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { getClientCardsWithExerciseId } from 'actions/clientCard'
import { AppHeader } from 'components/AppHeader'
import Disclosure from 'components/Disclosure'
import { Desktop, Mobile } from 'helpers/responsiveLayout'
import queryString from 'query-string'
import React, { Component, CSSProperties, Fragment } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { GlobalState } from 'reducers'
import { AppDispatch, history } from 'store'

const btnStyleObj = {
  desktop: {
    margin: '30px auto 0',
    width: '168px',
    height: '40px'
  },
  mobile: {
    margin: '30px auto 0',
    width: '100%',
    height: '40px'
  }
}

const contentStyle: CSSProperties = { padding: '0px', paddingBottom: '20px' }
const tileStyle: CSSProperties = {
  width: '730px',
  margin: '40px auto',
  padding: '0px',
  overflow: 'hidden'
}

export interface IntroductionProps {
  dispatch: AppDispatch
  location: Location
}

interface IntroductionState {
  exerciseId: string
}

class Introduction extends Component<IntroductionProps, IntroductionState> {
  public constructor(props: IntroductionProps) {
    super(props)
    const { search } = this.props.location
    this.state = {
      exerciseId: queryString.parse(search).exerciseId
        ? String(queryString.parse(search).exerciseId)
        : null
    }
  }

  async componentDidMount() {
    const { dispatch } = this.props
    const { exerciseId } = this.state
    if (exerciseId) {
      dispatch(getClientCardsWithExerciseId(exerciseId))
    }
  }

  navToWelcome = () => {
    history.push('/welcome')
  }

  introContent = (btnStyle: CSSProperties) => (
    <div className='welcome__display'>
      <div className='welcome__display-row'>
        <div className='welcome__display-illustration' />
      </div>
      <div className='welcome__display-row'>
        <div className='welcome__display-col'>
          <p className='welcome__display welcome__display-title'>Welcome</p>
          <p className='welcome__display welcome__display-copy'>
            HonestConversations is an interactive workshop created to help you
            articulate what’s truly important in your life.
          </p>
          <p className='welcome__display welcome__display-copy'>
            We designed this exercise so we can understand what really matters
            to you and help you make even better decisions. Because life isn’t
            just about having money, it’s about living richly.
          </p>
          <Button onClick={this.navToWelcome} btnStyle={btnStyle}>
            Got It!
          </Button>
        </div>
      </div>
    </div>
  )

  render() {
    return (
      <Fragment>
        <AppHeader progressBarStyles={{ display: 'none' }} />
        <Desktop>
          <Tile tileStyle={tileStyle} contentStyle={contentStyle}>
            {this.introContent(btnStyleObj.desktop)}
          </Tile>
        </Desktop>
        <Mobile>{this.introContent(btnStyleObj.mobile)}</Mobile>
        <Disclosure style={{ marginBottom: 20 }} />
      </Fragment>
    )
  }
}

export default connect()(Introduction)
