import { GET_CLIENT } from 'actions/client'
import { ClientObj } from 'objects/client'
import { AnyAction } from 'redux'

export const initialState: ClientObj = {
  name: null,
  householdId: null
}

const Client = (state = initialState, action: AnyAction) => {
  const newState = JSON.parse(JSON.stringify(state)) as ClientObj

  switch (action.type) {
    case `${GET_CLIENT}_FULFILLED`:
      newState.name = action.payload.data.name
      newState.householdId = action.payload.data.household
      return newState
    default:
      return state
  }
}

export default Client
