import { Tile } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { AppHeader } from 'components/AppHeader'
import React, { CSSProperties, Fragment, FunctionComponent } from 'react'
// tslint:disable-next-line: no-relative-imports
import { Desktop, Mobile } from '../../helpers/responsiveLayout'
import Disclosure from 'components/Disclosure'

const contentStyle: CSSProperties = { padding: '0px', paddingBottom: '20px' }
const tileStyle: CSSProperties = {
  width: '730px',
  margin: '40px auto',
  padding: '0px'
}

const content = () => (
  <div className='congrats__display'>
    <div className='congrats__display-row'>
      <div className='congrats__display-illustration' />
    </div>
    <div className='congrats__display-row'>
      <div className='congrats__display-col'>
        <p className='congrats__display congrats__display-title'>
          Congratulations!
        </p>
        <p className='congrats__display congrats__display-copy'>
          Thanks for prioritizing your cards from HonestConversation® exercise.
          Your advisor will be in touch with you to help you to finish the last
          step.
        </p>
      </div>
    </div>
  </div>
)

const Congratulations: FunctionComponent = () => (
  <Fragment>
    <AppHeader progressBarStyles={{ width: '0%' }} />
    <Desktop>
      <Tile tileStyle={tileStyle} contentStyle={contentStyle}>
        {content()}
      </Tile>
    </Desktop>
    <Mobile>{content()}</Mobile>
    <Disclosure style={{ marginBottom: 20 }} />
  </Fragment>
)

export default Congratulations
