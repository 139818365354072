import React, { Component } from 'react'

export type TooltipPosition =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'bottom-right'
  | 'bottom-left'
  | 'top-right'
  | 'top-left'

export interface TooltipProps {
  position: TooltipPosition
  message: string
  hideTooltip?: boolean
  labelName?: string
  target?: string
  width?: number
  multiLine?: boolean
  children?: React.ReactNode
}

export interface TooltipState {
  displayTooltip: boolean
}

// tslint:disable-next-line: no-suspicious-comment
// TODO: Children should not be optional in interface
class Tooltip extends Component<TooltipProps, TooltipState> {
  constructor(props: TooltipProps) {
    super(props)
    this.state = {
      displayTooltip: false
    }
  }

  public mouseEnter = () => {
    this.setState({ displayTooltip: true })
  }

  public mouseLeave = () => {
    this.setState({ displayTooltip: false })
  }

  public tooltipFunc = () => {
    const { message, position, target, children, width, multiLine } = this.props
    const { displayTooltip } = this.state

    // tslint:disable-next-line: no-suspicious-comment
    // TODO: we should use dangerouslySetInnerHTML to render the content from render
    return (
      <span className='tooltip' onMouseLeave={this.mouseLeave}>
        <span className='tooltip-trigger' onMouseOver={this.mouseEnter}>
          {target ? target : children}
        </span>
        {displayTooltip && (
          <div
            style={width ? { width } : { width: 'auto' }}
            className={`tooltip-bubble tooltip-position--${position}`}>
            <div
              className={
                multiLine
                  ? 'tooltip-message tooltip-message-left'
                  : 'tooltip-message'
              }>
              {message}
            </div>
          </div>
        )}
      </span>
    )
  }

  public render() {
    const { labelName, hideTooltip, children } = this.props
    if (!hideTooltip) {
      if (labelName) {
        return (
          <span className='tooltip-label-name'>
            {labelName}
            {this.tooltipFunc()}
          </span>
        )
      } else return this.tooltipFunc()
    } else return children
  }
}
export default Tooltip
