import React from 'react'

export interface ModalProps {
  dismissible?: boolean
  size?: 'S' | 'M' | 'L'
  header?: React.ReactNode | string
  footer?: React.ReactNode | string
  className?: string
  style?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
  onDismiss(): void
}

export class Modal extends React.Component<ModalProps> {
  public getWidth = () => {
    const { size } = this.props
    switch (size) {
      case 'S':
        return 430
      case 'M':
        return 600
      case 'L':
        return 820
      default:
        return 600
    }
  }

  public renderDismissButton = () => {
    const { onDismiss } = this.props
    return (
      <button
        type='button'
        className='modal__close--x'
        aria-label='Close'
        onClick={onDismiss}>
        <span aria-hidden='true'>&times;</span>
      </button>
    )
  }

  render() {
    const {
      dismissible,
      children,
      header,
      footer,
      style,
      wrapperStyle
    } = this.props
    return (
      <div className='modal__overlay'>
        <div
          className='modal__content-wrapper modal__content-wrapper--center'
          style={{ maxWidth: this.getWidth(), ...wrapperStyle }}>
          <div className='modal__content' style={{ ...style }}>
            <div className='modal__header'>
              {header}
              {dismissible ? this.renderDismissButton() : null}
            </div>
            <div className='modal__body'>{children}</div>
            <div className='modal__footer'>{footer}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
