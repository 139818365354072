import {
  Button,
  Tile
} from '@unitedcapitalfinancialadvisors/finlife-component-library'
import whyImage from 'assets/images/backgrounds/welcome/description-1.png'
import wantImage from 'assets/images/backgrounds/welcome/description-2.png'
import whenImage from 'assets/images/backgrounds/welcome/description-3.png'
import { AppHeader } from 'components/AppHeader'
import { Desktop, Mobile } from 'helpers/responsiveLayout'
import { CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { GlobalState } from 'reducers'
import { AppDispatch, history } from 'store'

import Disclosure from 'components/Disclosure'
import { getClientCardsWithExerciseId } from 'actions/clientCard'

const btnStyle = {
  width: '168px',
  margin: '30px auto 0',
  height: '40px'
}
const contentStyle = {
  padding: '0px',
  paddingBottom: '20px',
  backgroundColor: 'transparent',
  border: 'none'
}

const tileStyle = {
  width: '730px',
  margin: '40px auto',
  padding: '0px',
  overflow: 'hidden'
}

const imageStyle = { height: '140px', marginBottom: '5px' }

export interface DescriptionProps {
  authenticationFailed: boolean
  dispatch: AppDispatch
  exerciseId: string
  location: Location
}

class Description extends Component<DescriptionProps> {
  async componentDidMount() {
    const { dispatch, exerciseId } = this.props
    if (exerciseId) {
      dispatch(getClientCardsWithExerciseId(exerciseId))
    }
  }

  navToBegin = () => {
    const { exerciseId } = this.props
    if (exerciseId) {
      history.push({
        pathname: '/pick/fifteen',
        search: `?exerciseId=${exerciseId}`
      })
    } else {
      history.push('/pick/fifteen')
    }
  }

  desktopLayout = () => {
    return (
      <div>
        <Tile tileStyle={tileStyle} contentStyle={contentStyle}>
          <div className='welcome__display'>
            <div className='welcome__display-row'>
              <p className='welcome__display welcome__display-title welcome__display-title-sm'>
                This exercise will...
              </p>
            </div>
            <div className='welcome__display-row'>
              <div
                className={'welcome__display-col welcome__display-col-block'}>
                <div style={imageStyle}>
                  <img src={whyImage} alt='' role='presentation' width='134' />
                </div>
                <p className='welcome__display welcome__display-copy'>
                  Help us understand what matters to you and why
                </p>
              </div>
              <div
                className={'welcome__display-col welcome__display-col-block'}>
                <div style={imageStyle}>
                  <img src={wantImage} alt='' role='presentation' width='134' />
                </div>
                <p className='welcome__display welcome__display-copy'>
                  Get us in agreement on what you want to prioritize
                </p>
              </div>
              <div
                className={'welcome__display-col welcome__display-col-block'}>
                <div style={imageStyle}>
                  <img src={whenImage} alt='' role='presentation' width='134' />
                </div>
                <p className='welcome__display welcome__display-copy'>
                  Measure progress and provide focus for the future
                </p>
              </div>
            </div>
            <div className='welcome__display-row'>
              <Button onClick={this.navToBegin} btnStyle={btnStyle}>
                Let's Begin!
              </Button>
            </div>
          </div>
        </Tile>
      </div>
    )
  }

  mobileLayout = () => {
    return (
      <Fragment>
        <div className='welcome__display'>
          <div className='welcome__display-row'>
            <p className='welcome__display welcome__display-title welcome__display-title-sm'>
              This exercise will...
            </p>
          </div>
          <div className='welcome__carousel'>
            <CarouselProvider
              isPlaying={false}
              naturalSlideWidth={100}
              naturalSlideHeight={100}
              totalSlides={3}>
              <Slider>
                <Slide index={0}>
                  <div className='welcome__display-col welcome__display-col-block welcome__carousel-item'>
                    <img
                      src={whyImage}
                      alt=''
                      role='presentation'
                      style={{ maxWidth: '200px', width: '100%' }}
                    />
                    <p className='welcome__display welcome__display-copy welcome__carousel-copy'>
                      Help us understand what matters to you and why
                    </p>
                  </div>
                </Slide>
                <Slide index={1}>
                  <div className='welcome__display-col welcome__display-col-block welcome__carousel-item'>
                    <img
                      src={wantImage}
                      alt=''
                      role='presentation'
                      style={{ maxWidth: '200px', width: '100%' }}
                    />
                    <p className='welcome__display welcome__display-copy welcome__carousel-copy'>
                      Get us in agreement on what you want to prioritize
                    </p>
                  </div>
                </Slide>
                <Slide index={2}>
                  <div className='welcome__display-col welcome__display-col-block welcome__carousel-item'>
                    <img
                      src={whenImage}
                      alt=''
                      role='presentation'
                      style={{ maxWidth: '200px', width: '100%' }}
                    />
                    <p className='welcome__display welcome__display-copy welcome__carousel-copy'>
                      Measure progress and provide focus for the future
                    </p>
                  </div>
                </Slide>
              </Slider>
              <Dot slide={0} className='welcome__carousel-dot'>
                <span />
              </Dot>
              <Dot slide={1} className='welcome__carousel-dot'>
                <span />
              </Dot>
              <Dot slide={2} className='welcome__carousel-dot'>
                <span />
              </Dot>
            </CarouselProvider>

            <div className='welcome__display-col'>
              <Button
                onClick={this.navToBegin}
                btnStyle={{ ...btnStyle, width: '100%' }}>
                Let's Begin!
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  render() {
    return (
      <Fragment>
        <div className='welcome__w'>
          <div className='welcome__content'>
            <AppHeader
              progressBarStyles={{
                display: 'none'
              }}
            />
            <Desktop>{this.desktopLayout()}</Desktop>
            <Mobile>{this.mobileLayout()}</Mobile>
          </div>
          <Disclosure style={{ marginBottom: 20 }} />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    authenticationFailed: store.auth.authFailed,
    exerciseId: store.auth.exerciseId
  }
}

export default connect(mapStateToProps)(Description)
