import axios, { AxiosInstance } from 'axios'

const api = (): {
  getInstance(): AxiosInstance
  createInstance(accessToken: string, clientId?: string): void
} => {
  let instance: AxiosInstance = axios.create({
    baseURL: window._env_.REACT_APP_API_URL,
    timeout: 30000,
    maxContentLength: 65 * 1024 * 1024
  })

  const createInstance = (accessToken: string, clientId?: string): void => {
    const headers: { Authorization: string; Contact?: string } = {
      Authorization: `Bearer ${accessToken}`
    }
    if (clientId) {
      headers.Contact = clientId
    }
    instance = axios.create({
      baseURL: window._env_.REACT_APP_API_URL,
      timeout: 30000,
      maxContentLength: 65 * 1024 * 1024,
      headers
    })
  }

  const getInstance = (): AxiosInstance => {
    return instance
  }

  return { getInstance, createInstance }
}

export default api()
