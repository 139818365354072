import { GET_CLIENT_CARDS, RANK_CLIENT_CARDS } from 'actions/clientCard'
import { ClientCardObj } from 'objects/clientCard'
import { AnyAction } from 'redux'

export interface ClientCardInterface {
  [id: string]: ClientCardObj
}

export const initialState: ClientCardInterface = {}

const ClientCard = (state = initialState, action: AnyAction) => {
  const newState = JSON.parse(JSON.stringify(state)) as ClientCardInterface
  let clientCards: ClientCardObj[]
  switch (action.type) {
    case `${RANK_CLIENT_CARDS}_FULFILLED`:
      clientCards = action.payload.data
      clientCards.forEach((clientCard) => {
        newState[clientCard.id] = clientCard
      })
      return newState
    case `${GET_CLIENT_CARDS}_FULFILLED`:
      const emptyState = {}
      clientCards =
        action.payload && action.payload.data && action.payload.data.data
      clientCards.forEach((clientCard) => {
        emptyState[clientCard.id] = clientCard
      })
      return emptyState
    default:
      return state
  }
}

export default ClientCard
