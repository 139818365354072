import Api from 'helpers/api'

export const GET_CONTACTS = 'GET_CONTACTS'

export const getContacts = () => {
  const api = Api.getInstance()
  return {
    type: GET_CONTACTS,
    payload: api.get(`/contacts`).then((response) => response)
  }
}
