import {
  SHOW_INSTRUCTIONS_FIFTEEN_CARDS,
  SHOW_INSTRUCTIONS_FIVE_CARDS
} from 'actions/exerciseInstructions'
import { ExerciseInstructionsInterface } from 'objects/exerciseInstructions'
import { AnyAction } from 'redux'

export const initialState: ExerciseInstructionsInterface = {
  showFifteenCardPick: true,
  showFiveCardPick: true
}

const ExerciseInstructionsModal = (state = initialState, action: AnyAction) => {
  const newState = state

  switch (action.type) {
    case `${SHOW_INSTRUCTIONS_FIFTEEN_CARDS}`:
      return { ...newState, showFifteenCardPick: action.payload }
    case `${SHOW_INSTRUCTIONS_FIVE_CARDS}`:
      return { ...newState, showFiveCardPick: action.payload }
    default:
      return newState
  }
}

export default ExerciseInstructionsModal
