import { ClientCardObj } from 'objects/clientCard'
import React, { Component } from 'react'

import Disclosure from 'components/Disclosure'

import IconCheckWhite from 'assets/images/icons/general/check_white.png'

import IconCommitmentColor from 'assets/images/icons/commitment-color.png'
import IconHappinessColor from 'assets/images/icons/happiness-color.png'
import IconProtectionColor from 'assets/images/icons/protection-color.png'

import IconCommitmentWhite from 'assets/images/icons/commitment-white.png'
import IconHappinessWhite from 'assets/images/icons/happiness-white.png'
import IconProtectionWhite from 'assets/images/icons/protection-white.png'

import SpinnerIcon from 'assets/images/icons/spinner.svg'

// import IconCheckWhite from '../../assets/images/icons/general/check_white.png'
import { CardCategoryType } from 'objects/card'

export interface FifteenCardHolderProps {
  activeCard: CardCategoryType
  layout: 'mobile' | 'desktop'
  finishedCards?: {
    Commitment: boolean
    Protection: boolean
    Happiness: boolean
  }
  happinessCards?: ClientCardObj[]
  commitmentCards?: ClientCardObj[]
  protectionCards?: ClientCardObj[]
  changeCardType(type: CardCategoryType, isMobile: boolean): void
}

class FifteenCardHolder extends Component<FifteenCardHolderProps> {
  cardDeck = (
    cardType: CardCategoryType,
    activeIcon: string,
    inactiveIcon: string,
    isCardListSorted: boolean
  ) => {
    const { activeCard, changeCardType, layout } = this.props
    const cardDeckClass =
      activeCard === cardType
        ? ''
        : isCardListSorted
        ? 'fifteen-card-holder__item--stack fifteen-card-holder__item--complete'
        : 'fifteen-card-holder__item--stack'
    const cardDeckIcon = activeCard === cardType ? activeIcon : inactiveIcon

    if (activeCard && layout === 'mobile') {
      return null
    }
    const isMobile = layout === 'mobile' ? true : false
    return (
      <div
        onClick={() => changeCardType(cardType, isMobile)}
        className={`fifteen-card-holder__item fifteen-card-holder__${cardType.toLowerCase()}  ${cardDeckClass}`}>
        <img src={cardDeckIcon} alt={`${cardType} Icon`} />
        {isCardListSorted && (
          <img
            src={IconCheckWhite}
            alt='check mark'
            className='fifteen-card-holder__item-check'
          />
        )}
        <span>{cardType}</span>
      </div>
    )
  }

  public renderFifteenCardHolder = () => {
    const {
      happinessCards,
      commitmentCards,
      protectionCards,
      finishedCards,
      layout
    } = this.props
    const cardListSorted = (cardList: ClientCardObj[]) => {
      const cardWithRankFive = cardList.filter((card: ClientCardObj) => {
        return card.ranking === 5
      })
      return Object.keys(cardWithRankFive).length === 1
    }
    return (
      <div className='fifteen-card-holder__card-w'>
        {this.cardDeck(
          'Protection',
          IconProtectionColor,
          IconProtectionWhite,
          layout === 'mobile'
            ? finishedCards.Protection
            : cardListSorted(protectionCards)
        )}
        {this.cardDeck(
          'Commitment',
          IconCommitmentColor,
          IconCommitmentWhite,
          layout === 'mobile'
            ? finishedCards.Commitment
            : cardListSorted(commitmentCards)
        )}
        {this.cardDeck(
          'Happiness',
          IconHappinessColor,
          IconHappinessWhite,
          layout === 'mobile'
            ? finishedCards.Happiness
            : cardListSorted(happinessCards)
        )}
      </div>
    )
  }

  public renderLoadingTile = () => {
    return (
      <div className='fifteen-card-holder__loading-tile-w'>
        <img
          className='fifteen-card-holder__loading-icon'
          src={SpinnerIcon}
          alt='spinner'
          height={68}
          width={68}
        />
        <p className='fifteen-card-holder__loading-text'>
          Loading HonestConversations® Cards
        </p>
      </div>
    )
  }

  render() {
    const {
      happinessCards,
      commitmentCards,
      protectionCards,
      layout
    } = this.props
    return (
      <div className='fifteen-card-holder__w'>
        {happinessCards?.length &&
        commitmentCards?.length &&
        protectionCards?.length
          ? this.renderFifteenCardHolder()
          : this.renderLoadingTile()}
        {layout === 'mobile' ? <Disclosure /> : null}
      </div>
    )
  }
}

export default FifteenCardHolder
