import * as React from 'react'

const MaintenancePage = () => {
  return (
    <div className='maintenance__wrapper'>
      <div className='maintenance__container'>
        <div className='maintenance__content'>
          <div className='maintenance__title'>Planned Maintenance</div>
          <div className='maintenance__body'>
            <p>
              Website is currently undergoing scheduled maintenance and should
              be back shortly.
            </p>
            <p>
              Check for outages and subscribe to receive notifications{' '}
              <a
                href='https://status.finlife.com/'
                target='_blank'
                rel='noopener noreferrer'>
                here.
              </a>
            </p>
          </div>
        </div>
        <div className='maintenance__footer'>
          ©{new Date().getFullYear()}| United Capital Financial Advisors, LLC
          (“United Capital”). All Rights Reserved. | FinLife Partners is a d/b/a
          of United Capital Financial Advisors, LLC
          <div className='maintenance__footer-links'>
            <a
              href={'https://www.unitedcapitalwealth.com/privacy-policy/'}
              target='_blank'
              rel='noopener noreferrer'>
              Privacy Policy
            </a>
            <div className='maintenance__footer-seperator'> | </div>
            <a
              href={'https://www.unitedcapitalwealth.com/disclosures/'}
              target='_blank'
              rel='noopener noreferrer'>
              Site Disclosures
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaintenancePage
