import Api from 'helpers/api'

export const GET_CLIENT = 'GET_CLIENT'

export const getClient = () => {
  const api = Api.getInstance()
  return {
    type: GET_CLIENT,
    payload: api.get(`/honestconversation/client`).then((response) => {
      return response
    })
  }
}
