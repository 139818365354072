import { ExerciseInstructionsInterface } from 'objects/exerciseInstructions'
import { connectRouter, RouterState } from 'connected-react-router'
import auth, { AuthenticationInterface } from 'reducers/authentication'
import clientCard, { ClientCardInterface } from 'reducers/clientCard'
import exerciseInstructionsModal from 'reducers/exerciseInstructions'
import client from './client'
import { ClientObj } from 'objects/client'
import { ContactObj } from 'objects/contacts'
import contacts from 'reducers/contacts'
import { AnyAction, combineReducers } from 'redux'

export interface GlobalState {
  auth: AuthenticationInterface
  router: RouterState
  clientCard: ClientCardInterface
  exerciseInstructionsModal: ExerciseInstructionsInterface
  client: ClientObj
  contacts: ContactObj
}

export const appReducer = (history: any) =>
  combineReducers<GlobalState>({
    auth,
    router: connectRouter(history),
    clientCard,
    exerciseInstructionsModal,
    client,
    contacts
  })

const rootReducer = (history: any) =>
  combineReducers<GlobalState>({
    auth,
    router: connectRouter(history),
    clientCard,
    exerciseInstructionsModal,
    client,
    contacts
  })

export default rootReducer
