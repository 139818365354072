import React from 'react'
import InstructionsModal from 'components/InstructionsModal'
import Pick15 from 'assets/images/fifteen_card_pick/mobiletutorial-pick15.gif'
import Pick15Still from 'assets/images/fifteen_card_pick/tutorial-01-step-1-mobile_still.png'

export interface FifteenCardPickInstructionsProps {
  onDismiss(): void
}
class FifteenCardPickInstructions extends React.Component<
  FifteenCardPickInstructionsProps
> {
  render() {
    const { onDismiss } = this.props
    const instructions = {
      mobile: Pick15,
      still: Pick15Still
    }
    return (
      <InstructionsModal
        onDismiss={onDismiss}
        instructions={instructions}
        instructionGif='fifteen'
        web={false}
      />
    )
  }
}

export default FifteenCardPickInstructions
