import React, { Component } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { ClientCardObj } from 'objects/clientCard'

export interface FiveCardTableProps {
  droppableId: string
  cards: ClientCardObj[]
  droppableTable: string
}

interface FiveCardTableState {
  showCards: boolean
}

class FiveCardTable extends Component<FiveCardTableProps, FiveCardTableState> {
  constructor(props: FiveCardTableProps) {
    super(props)
    this.state = {
      showCards: false
    }
  }

  toggleShowCards = () => {
    this.setState({
      showCards: !this.state.showCards
    })
  }

  render() {
    const { showCards } = this.state
    const { droppableId, cards, droppableTable } = this.props
    return (
      <div
        className={
          showCards
            ? 'five-card-table__table-w five-card-table__table-w--open'
            : 'five-card-table__table-w'
        }>
        <Droppable
          droppableId={droppableId}
          direction='vertical'
          isDropDisabled={droppableId === droppableTable ? false : true}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {cards.map((card: ClientCardObj, index: number) => {
                const clientCard = card.card

                return (
                  <Draggable
                    key={`${card.id}`}
                    draggableId={`${card.id}`}
                    index={card.ranking - 1}>
                    {(providedDraggable) => (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        style={{
                          position: 'relative',
                          zIndex: cards.length - index,
                          ...providedDraggable.draggableProps.style
                        }}>
                        <Card
                          category={clientCard.category}
                          title={clientCard.title}
                          cardStyle={{
                            marginBottom: showCards ? '-25px' : '-125px',
                            marginTop: '10px',
                            transition: 'all 300ms'
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <span
          className={
            cards.length > 1
              ? 'five-card-table__drop-down'
              : 'five-card-table__drop-down five-card-table__drop-down--hide'
          }
          onClick={this.toggleShowCards}
        />
      </div>
    )
  }
}

export default FiveCardTable
