const reactResponsive = require('react-responsive')

export const Desktop = ({ children }: any) => {
  const isDesktop = reactResponsive.useMediaQuery({ minWidth: 751 })
  return isDesktop ? children : null
}

export const Mobile = ({ children }: any) => {
  const isMobile = reactResponsive.useMediaQuery({ maxWidth: 750 })
  return isMobile ? children : null
}
