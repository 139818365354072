import React, { Fragment, RefObject } from 'react'
import GifPlayer from 'react-gif-player'
import { Button } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { AppHeader } from 'components/AppHeader'
import Modal from 'components/Modal'
import { Desktop, Mobile } from 'helpers/responsiveLayout'

export interface InstructionsModalProps {
  instructions: any
  instructionGif: string
  web: boolean
  onDismiss(): void
}

interface InstructionsModalState {
  playing: boolean
}

class InstructionsModal extends React.Component<
  InstructionsModalProps,
  InstructionsModalState
> {
  pauseGif: RefObject<boolean> = React.createRef()
  constructor(props: InstructionsModalProps) {
    super(props)

    this.state = {
      playing: true
    }
  }

  private timer: NodeJS.Timeout = null

  delay = async (time: any) => {
    return new Promise<void>((resolve, reject) => {
      this.timer = setTimeout(() => {
        resolve()
      }, time)
    })
  }

  async componentDidMount() {
    const { instructionGif, web } = this.props
    const delayForWeb = instructionGif === 'fifteen' ? 13000 : 8000
    const delayForMobile = instructionGif === 'fifteen' ? 9000 : 11000
    const delay = web === true ? delayForWeb : delayForMobile
    this.delay(delay).then(() => {
      this.setState({
        playing: false
      })
    })
  }

  componentWillUnmount(): void {
    clearTimeout(this.timer)
  }

  handleTogglPlay = (playing: boolean) => {
    const { instructionGif, web } = this.props
    const delayForWeb = instructionGif === 'fifteen' ? 13000 : 8000
    const delayForMobile = instructionGif === 'fifteen' ? 9000 : 11000
    const delay = web === true ? delayForWeb : delayForMobile
    this.setState({
      playing: playing
    })
    this.delay(delay).then(() => {
      this.setState({
        playing: false
      })
    })
  }

  renderSlides() {
    const { instructions } = this.props
    return (
      <div className='instructions-carousel__slide'>
        {this.state.playing ? (
          <GifPlayer
            key={1}
            gif={instructions.web}
            still={instructions.still}
            onTogglePlay={this.handleTogglPlay}
            autoplay={this.state.playing}
          />
        ) : (
          <GifPlayer
            key={2}
            still={instructions.still}
            gif={instructions.web}
            onTogglePlay={this.handleTogglPlay}
            autoplay={this.state.playing}
          />
        )}
      </div>
    )
  }

  renderPlayControls() {
    const { onDismiss } = this.props
    return (
      <Button
        onClick={onDismiss}
        className='instructions-carousel__slider-controls__btn--next'>
        Got It!
      </Button>
    )
  }

  renderDesktopLayout() {
    return (
      <div className='instructions-carousel'>
        <div className='instructions-carousel__slider'>
          {this.renderSlides()}
        </div>
        {this.renderPlayControls()}
      </div>
    )
  }

  renderMobileSlides() {
    const { instructions } = this.props
    return this.state.playing ? (
      <div className='instructions-carousel__slide'>
        <GifPlayer
          key={1}
          gif={instructions.mobile}
          still={instructions.still}
          onTogglePlay={this.handleTogglPlay}
          autoplay={true}
        />
      </div>
    ) : (
      <div className='instructions-carousel__slide'>
        <GifPlayer
          key={2}
          gif={instructions.mobile}
          still={instructions.still}
          onTogglePlay={this.handleTogglPlay}
          autoplay={false}
        />
      </div>
    )
  }

  renderMobileLayout() {
    return (
      <Fragment>
        <AppHeader progressBarStyles={{ display: 'none' }} />
        <div className='instructions-carousel'>
          <div className='instructions-carousel__slider'>
            {this.renderMobileSlides()}
          </div>
          {this.renderPlayControls()}
        </div>
      </Fragment>
    )
  }

  render() {
    const { onDismiss } = this.props
    return (
      <Modal
        dismissible={false}
        onDismiss={onDismiss}
        style={{ paddingBottom: 0, minHeight: '100%' }}>
        <Desktop>{this.renderDesktopLayout()}</Desktop>
        <Mobile>{this.renderMobileLayout()}</Mobile>
      </Modal>
    )
  }
}

export default InstructionsModal
