import React, { Component } from 'react'

export interface PageHeadingProps {
  sectionNumber: string
  text: string
}
class PageHeading extends Component<PageHeadingProps> {
  render() {
    const { sectionNumber, text } = this.props
    return (
      <div className='page-header__w'>
        <span className='page-header__number'>{sectionNumber}</span>
        <p className='page-header__text'>{text}</p>
      </div>
    )
  }
}

export default PageHeading
