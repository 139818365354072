import { CardCategoryType } from 'objects/card'
import { ClientCardObj } from 'objects/clientCard'
import React, { Component } from 'react'
import FiveCardMobileCard from './FiveCardMobileCard'

export interface FiveCardMobileDeckProps {
  expanded: boolean
  cards: ClientCardObj[]
  cardType: CardCategoryType
  pickedCards: ClientCardObj[]
  addPick(e: any, card: ClientCardObj): void
  toggleExpandCard(cardType: CardCategoryType): void
}

class FiveCardMobileDeck extends Component<FiveCardMobileDeckProps> {
  render() {
    const {
      expanded,
      cards,
      addPick,
      toggleExpandCard,
      pickedCards
    } = this.props
    return (
      <div
        className={
          expanded
            ? 'five-card-mobile-table__cards-w five-card-mobile-table__cards-w--active'
            : 'five-card-mobile-table__cards-w'
        }>
        {cards.map((card, index: number) => (
          <FiveCardMobileCard
            key={index}
            index={index}
            card={card}
            addPick={addPick}
            expanded={expanded}
            toggleExpandCard={toggleExpandCard}
            pickedCards={pickedCards}
          />
        ))}
      </div>
    )
  }
}

export default FiveCardMobileDeck
