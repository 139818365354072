import React, { Component, Fragment } from 'react'
import {
  DragDropContext,
  DropResult,
  DragUpdate,
  ResponderProvided
} from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Desktop, Mobile } from 'helpers/responsiveLayout'

import {
  addOrEditCardFiveRanking,
  addOrEditCardFiveRankingWithExerciseId,
  getClientCardsWithExerciseId
} from 'actions/clientCard'
import { CardCategoryType } from 'objects/card'
import { ClientCardObj, ClientCardPickFiveObj } from 'objects/clientCard'
import { GlobalState } from 'reducers'
import { ClientCardInterface } from 'reducers/clientCard'
import { AppDispatch, history } from 'store'

import { Button } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { AppHeader } from 'components/AppHeader'
import FiveCardArranger from 'components/FiveCardArranger'
import PageHeading from 'components/PageHeading'
import Tile from 'components/Tile'
import Tooltip from 'components/ToolTip'
import FiveCardMobileTable from './FiveCardMobileTable'
import FiveCardPickInstructions from './FiveCardPickInstructions'
import FiveCardPickMobileInstructions from './FiveCardPickMobileInstructions'
import FiveCardTable from './FiveCardTable'

import Disclosure from 'components/Disclosure'

import { showPickFiveInstructions } from 'actions/exerciseInstructions'
import ArrowIcon from 'assets/images/icons/general/arrow_blue.png'
import { ExerciseInstructionsInterface } from 'objects/exerciseInstructions'
import { getCookie } from 'helpers'

export interface FiveCardProps {
  location: Location
  protectionCards: ClientCardObj[]
  commitmentCards: ClientCardObj[]
  happinessCards: ClientCardObj[]
  selectedCards: ClientCardObj[]
  clientCards: ClientCardInterface
  exerciseId: string
  dispatch: AppDispatch
  exerciseInstructionsModal: ExerciseInstructionsInterface
  clientId: string
  householdId: string
  secondaryContact: boolean
}

interface FiveCardPickState {
  selectedCards: ClientCardObj[]
  droppableTable: string
}

class FiveCardPick extends Component<FiveCardProps, FiveCardPickState> {
  constructor(props: FiveCardProps) {
    super(props)
    this.state = {
      selectedCards: props.selectedCards,
      droppableTable: null
    }
  }

  public async componentDidMount() {
    const { dispatch, exerciseId } = this.props
    if (exerciseId) {
      dispatch(getClientCardsWithExerciseId(exerciseId))
    }
  }

  public componentDidUpdate(prevProps: FiveCardProps) {
    if (prevProps.selectedCards?.length !== this.props.selectedCards?.length) {
      this.setState({ selectedCards: this.props.selectedCards })
    }
  }

  reorder = (list: ClientCardObj[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  onDragUpdate = (
    draggableDragUpdate: DragUpdate,
    provided: ResponderProvided
  ) => {
    // current active droppable component
    const { droppableTable } = this.state
    const { draggableId } = draggableDragUpdate
    const cardCategoryType = draggableId.split('-')[
      draggableId.split('-').length - 1
    ]
    const categoryName = cardCategoryType?.toLocaleLowerCase()
    const cardTableSource = `${categoryName}FiveTable`

    if (droppableTable !== cardTableSource) {
      this.setState({
        droppableTable: cardTableSource
      })
    }
  }

  onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result
    const { clientCards } = this.props
    const { selectedCards } = this.state
    const sourceId = source.droppableId
    const destId = destination && destination.droppableId
    const clientCardId = draggableId
    if (!destination) {
      return
    }

    if (sourceId !== destId) {
      if (destId === 'sortedCards') {
        if (selectedCards.length === 5) {
          return
        }
        const card = Object.values(clientCards).filter((card) => {
          return clientCardId === card.id
        })[0]
        this.addCardToFiveList(destination.index, card)
      } else {
        this.removeCardFromFiveList(source.index)
      }
    } else if (destId === 'sortedCards') {
      this.arrangeCardsInFiveList(result)
    }
    return
  }

  addCardToFiveList = (index: number, card: ClientCardObj) => {
    const { selectedCards } = this.state

    const updatedSelectedCards = [...selectedCards]
    updatedSelectedCards.splice(index, 0, card)
    this.setState({
      selectedCards: updatedSelectedCards
    })
  }

  removeCardFromFiveList = (index: number) => {
    const { selectedCards } = this.state

    const updatedSelectedCards = [...selectedCards]
    updatedSelectedCards.splice(index, 1)
    this.setState({ selectedCards: updatedSelectedCards })
  }

  arrangeCardsInFiveList = (result: DropResult) => {
    const { selectedCards } = this.state
    const { destination, source } = result

    const reorderResult = this.reorder(
      selectedCards,
      source.index,
      destination.index
    )
    this.setState({ selectedCards: reorderResult })
  }

  cardsSelected = () => {
    const { selectedCards } = this.state
    return selectedCards.length === 5
  }

  toggleShowInstructionsModal = () => {
    this.props.dispatch(showPickFiveInstructions(false))
  }

  navigateToNextPage = () => {
    const gcToken = getCookie('gcToken')
    const redirect_uri = sessionStorage.getItem('redirect_uri')
    if (gcToken || !redirect_uri) {
      history.push('/congratulations')
    } else {
      window.location.href = redirect_uri
    }
  }

  nextPage = async () => {
    const { dispatch, exerciseId } = this.props
    const { selectedCards } = this.state
    if (this.cardsSelected()) {
      let cardObj: any = {}
      const selectedCardArray: ClientCardPickFiveObj[] = []
      // Grab all the cards that were removed so we can reuse that record
      // This upsert functionality should move to the BE where it can be done a lot cleaner
      const removedCards = this.props.selectedCards.filter(
        (selectedCard) => !selectedCards.includes(selectedCard)
      )
      let removedCardIndex = 0

      if (selectedCards[0] && selectedCards[0].id) {
        selectedCards.map((selectedCard, index) => {
          if (this.props.selectedCards.includes(selectedCard)) {
            cardObj = {
              ...selectedCard,
              ranking: index + 1
            }
          } else {
            cardObj = {
              ...selectedCard,
              id:
                removedCards &&
                removedCards[removedCardIndex] &&
                removedCards[removedCardIndex].id,
              ranking: index + 1,
              rankedWithin: 'All'
            }
            removedCardIndex = removedCardIndex + 1
          }
          selectedCardArray.push(cardObj)
        })
        if (exerciseId && exerciseId !== 'null') {
          await dispatch(
            addOrEditCardFiveRankingWithExerciseId(
              selectedCards[0].clientId,
              selectedCardArray,
              exerciseId
            )
          )
          this.navigateToNextPage()
        } else {
          await dispatch(
            addOrEditCardFiveRanking(
              selectedCards[0].clientId,
              exerciseId,
              selectedCardArray
            )
          )
          this.navigateToNextPage()
        }
      }
    }
  }

  navigateToPickFifteen = () => {
    history.push('/pick/fifteen')
  }

  tileHeader = () => {
    return (
      <Fragment>
        <Button
          className='tile__btn-back'
          btnStyle={{
            backgroundColor: 'transparent',
            padding: 0
          }}
          onClick={this.navigateToPickFifteen}>
          <img
            className='transform__rotate-180'
            src={ArrowIcon}
            alt='arrow icon'
          />
          Back
        </Button>
        <Tooltip
          message='Please select all 5 cards by moving them into the selection below before moving on.'
          width={180}
          position='bottom'
          multiLine={true}
          hideTooltip={this.cardsSelected() ? true : false}>
          <Button
            type={this.cardsSelected() ? null : 'disabled'}
            onClick={this.nextPage}>
            Finish
          </Button>
        </Tooltip>
      </Fragment>
    )
  }

  render() {
    const { selectedCards, droppableTable } = this.state
    const { protectionCards, commitmentCards, happinessCards } = this.props
    const { exerciseInstructionsModal } = this.props
    const availableProtectionCards: ClientCardObj[] = []
    const availableCommitmentCards: ClientCardObj[] = []
    const availableHappinessCards: ClientCardObj[] = []

    const selectedCardIds = selectedCards.map(
      (selectedCard) => selectedCard.cardId
    )

    protectionCards.forEach((card) => {
      if (!selectedCardIds.includes(card.cardId)) {
        availableProtectionCards.push(card)
      }
    })
    commitmentCards.forEach((card) => {
      if (!selectedCardIds.includes(card.cardId)) {
        availableCommitmentCards.push(card)
      }
    })
    happinessCards.forEach((card) => {
      if (!selectedCardIds.includes(card.cardId)) {
        availableHappinessCards.push(card)
      }
    })

    return (
      <Fragment>
        <div className='personal-priorities__w'>
          <Desktop>
            <div className='five-card-pick__content'>
              <AppHeader
                progressBarStyles={{ width: '80%' }}
                middleHeader={
                  <PageHeading sectionNumber='1' text='Personal Priorities' />
                }
              />
              <Tile
                tileHeader={this.tileHeader()}
                contentStyle={{ height: '650px' }}
                tileStyle={{ marginTop: '30px' }}>
                <DragDropContext
                  onDragUpdate={this.onDragUpdate}
                  onDragEnd={this.onDragEnd}>
                  <div className='five-card-table'>
                    <FiveCardTable
                      droppableId='protectionFiveTable'
                      cards={availableProtectionCards}
                      droppableTable={droppableTable}
                    />
                    <FiveCardTable
                      droppableId='commitmentFiveTable'
                      cards={availableCommitmentCards}
                      droppableTable={droppableTable}
                    />
                    <FiveCardTable
                      droppableId='happinessFiveTable'
                      cards={availableHappinessCards}
                      droppableTable={droppableTable}
                    />
                  </div>

                  <FiveCardArranger cards={selectedCards} />
                </DragDropContext>
              </Tile>
            </div>
            <Disclosure style={{ maxWidth: 960 }} />
          </Desktop>
          <Mobile>
            <AppHeader progressBarStyles={{ width: '80%' }} />
            <FiveCardMobileTable
              protectionCards={protectionCards}
              commitmentCards={commitmentCards}
              happinessCards={happinessCards}
            />
          </Mobile>
        </div>

        {exerciseInstructionsModal.showFiveCardPick ? (
          <Fragment>
            <Desktop>
              <FiveCardPickInstructions
                onDismiss={this.toggleShowInstructionsModal}
              />
            </Desktop>
            <Mobile>
              <FiveCardPickMobileInstructions
                onDismiss={this.toggleShowInstructionsModal}
              />
            </Mobile>
          </Fragment>
        ) : null}
      </Fragment>
    )
  }
}

const getCards = (
  clientCards: ClientCardInterface,
  cardType: CardCategoryType,
  selectedCards: ClientCardObj[]
) => {
  return Object.keys(clientCards)
    .filter((id) => clientCards[id].rankedWithin === cardType)
    .map((id) => clientCards[id])
    .sort((a, b) => a.ranking - b.ranking)
}

const mapStateToProps = (store: GlobalState) => {
  const clientCards = store.clientCard
  const selectedCards = Object.keys(clientCards)
    .filter((id) => clientCards[id].rankedWithin === 'All')
    .map((id) => clientCards[id])
    .sort((a, b) => a.ranking - b.ranking)

  const protectionCards = getCards(clientCards, 'Protection', selectedCards)
  const commitmentCards = getCards(clientCards, 'Commitment', selectedCards)
  const happinessCards = getCards(clientCards, 'Happiness', selectedCards)

  return {
    exerciseInstructionsModal: store.exerciseInstructionsModal,
    protectionCards,
    commitmentCards,
    happinessCards,
    selectedCards,
    clientCards: store.clientCard,
    exerciseId: store.auth.exerciseId,
    clientId: store.auth.clientId,
    householdId: store.client.householdId,
    secondaryContact: store.contacts.secondaryContact
  }
}
export default connect(mapStateToProps)(FiveCardPick)
