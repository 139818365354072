import { Button } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import ArrowBlue from 'assets/images/icons/general/arrow_blue.png'
import React, { Component, Fragment } from 'react'

interface MobileAppHeaderState {
  deviceType: 'mobile' | 'tablet' | 'desktop'
}

export interface MobileAppHeaderProps {
  nextButtonProceed: boolean
  nextButtonText?: string
  backButtonText?: string
  backButtonRequired: boolean
  nextButtonOnClick(): void
  backButtonOnClick?(): void
}

class MobileAppHeader extends Component<
  MobileAppHeaderProps,
  MobileAppHeaderState
> {
  constructor(props: MobileAppHeaderProps) {
    super(props)
    this.state = {
      deviceType: null
    }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 750) {
      this.setState({ deviceType: 'mobile' })
    } else if (window.innerWidth > 900) {
      this.setState({ deviceType: 'desktop' })
    } else {
      this.setState({ deviceType: 'tablet' })
    }
  }

  mobileHeaderRender = () => {
    const {
      nextButtonText,
      nextButtonOnClick,
      backButtonText,
      backButtonOnClick,
      nextButtonProceed,
      backButtonRequired
    } = this.props
    return (
      <div className='header__mobile-bottom'>
        {backButtonRequired ? (
          <Button
            btnStyle={{ padding: '0', fontWeight: 'bold', color: '#255eba' }}
            type='clear'
            onClick={backButtonOnClick}>
            <img
              src={ArrowBlue}
              style={{ transform: 'rotate(180deg)' }}
              alt='back arrow icon'
            />
            {backButtonText ? backButtonText : 'Back'}
          </Button>
        ) : null}
        Personal Priorities
        <Button
          onClick={nextButtonProceed ? nextButtonOnClick : null}
          btnStyle={{ padding: '9px 14px' }}
          type={nextButtonProceed ? null : 'disabled'}>
          {nextButtonText ? nextButtonText : 'Next'}
        </Button>
      </div>
    )
  }
  render() {
    const { deviceType } = this.state
    return (
      <Fragment>
        {deviceType === 'mobile' ? this.mobileHeaderRender() : null}
      </Fragment>
    )
  }
}

export default MobileAppHeader
