import { Desktop, Mobile } from 'helpers/responsiveLayout'
import React, { Component, Fragment } from 'react'
import {
  DragDropContext,
  DraggableLocation,
  DropResult
} from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import {
  getClientCardsWithExerciseId,
  rankClientCard,
  rankClientCardWithExerciseId
} from 'actions/clientCard'
import { CardCategoryType } from 'objects/card'
import { ClientCardInterface, ClientCardObj } from 'objects/clientCard'
import { GlobalState } from 'reducers'
import { AppDispatch, history } from 'store'

import { Button } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { showPickFifteenInstructions } from 'actions/exerciseInstructions'
import { AppHeader, MobileAppHeader } from 'components/AppHeader'
import Disclosure from 'components/Disclosure'
import FiveCardArranger from 'components/FiveCardArranger'
import PageHeading from 'components/PageHeading'
import Tile from 'components/Tile'
import Tooltip from 'components/ToolTip'
import { ExerciseInstructionsInterface } from 'objects/exerciseInstructions'
import FifteenCardHolder from './FifteenCardHolder'
import FifteenCardPickInstructions from './FifteenCardPickInstructions'
import FifteenCardPickMobileInstructions from './FifteenCardPickMobileInstructions'
import FifteenCardTable from './FifteenCardTable'

export interface FifteenCardProps {
  clientCards: ClientCardInterface
  location: Location
  protectionCards: ClientCardObj[]
  commitmentCards: ClientCardObj[]
  happinessCards: ClientCardObj[]
  dispatch: AppDispatch
  exerciseInstructionsModal: ExerciseInstructionsInterface
  exerciseId: string
}

interface FifteenCardPickState {
  unsortedCards: ClientCardObj[]
  sortedCards: ClientCardObj[]
  activeCard: CardCategoryType
  finishedCards: {
    Commitment: boolean
    Protection: boolean
    Happiness: boolean
  }
  showMobileCardTable: boolean
  clientId: string
  mobileSortedCommitment: ClientCardObj[]
  mobileSortedProtection: ClientCardObj[]
  mobileSortedHappiness: ClientCardObj[]
}

class FifteenCardPick extends Component<
  FifteenCardProps,
  FifteenCardPickState
> {
  constructor(props: FifteenCardProps) {
    super(props)
    this.state = {
      activeCard: null,
      unsortedCards: [],
      sortedCards: [],
      finishedCards: {
        Commitment: false,
        Protection: false,
        Happiness: false
      },
      showMobileCardTable: false,
      clientId: null,
      mobileSortedCommitment: [],
      mobileSortedProtection: [],
      mobileSortedHappiness: []
    }
  }

  public async componentDidMount() {
    const {
      happinessCards,
      commitmentCards,
      protectionCards,
      exerciseId,
      dispatch
    } = this.props
    if (
      !happinessCards.length ||
      !commitmentCards.length ||
      !protectionCards.length
    ) {
      dispatch(getClientCardsWithExerciseId(exerciseId))
    }
  }

  reorder = (list: ClientCardObj[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    // Adjust Card ranking
    const clientCards: ClientCardObj[] = []
    result.forEach((card, index) => {
      const clientCard = {
        ...card,
        ranking: index + 1
      }
      clientCards.push(clientCard)
    })
    return clientCards
  }

  move = (
    source: ClientCardObj[],
    destination: ClientCardObj[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)

    destClone.splice(
      droppableDestination.index,
      0,
      sourceClone[droppableSource.index]
    )
    sourceClone.splice(droppableSource.index, 1)
    const result: { [id: string]: ClientCardObj[] } = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    return result
  }

  onDragEnd = (result: DropResult) => {
    const { destination, source } = result
    const { sortedCards, unsortedCards } = this.state
    const { dispatch, exerciseId } = this.props

    if (!destination) {
      return
    }

    if (source.droppableId !== destination.droppableId) {
      const sourceCards =
        source.droppableId === 'sortedCards' ? sortedCards : unsortedCards
      const destinationCards =
        source.droppableId === 'sortedCards' ? unsortedCards : sortedCards
      const moveResult = this.move(
        sourceCards,
        destinationCards,
        source,
        destination
      )
      this.setState({
        unsortedCards: moveResult.unsortedCards,
        sortedCards: moveResult.sortedCards
      })
    } else if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'sortedCards') {
        const moveResult = this.reorder(
          sortedCards,
          source.index,
          destination.index
        )
        this.setState({
          sortedCards: moveResult
        })
      } else {
        const moveResult = this.reorder(
          this.state.unsortedCards,
          source.index,
          destination.index
        )
        this.setState({
          unsortedCards: moveResult
        })
      }
    }

    // we have to add the last card from unsortedCards to our rankClientCard call
    if (
      unsortedCards.length === 1 &&
      result.source.index === 0 &&
      result.destination.droppableId !== 'unsortedCards'
    ) {
      let clientId
      sortedCards.forEach((sortedCard, index) => {
        sortedCards[index].ranking = index + 1
        clientId = sortedCard && sortedCard.clientId
      })

      const updatedSortedCards = [...sortedCards]
      unsortedCards[0].ranking = 5
      updatedSortedCards.push(unsortedCards[0])

      if (exerciseId) {
        dispatch(
          rankClientCardWithExerciseId(clientId, updatedSortedCards, exerciseId)
        )
      } else dispatch(rankClientCard(clientId, exerciseId, updatedSortedCards))
      // Check if all cards have a ranking to activate the "Next" button
      this.cardsSorted()
    }
  }

  onDragEndMobile = (result: DropResult) => {
    const {
      mobileSortedProtection,
      mobileSortedCommitment,
      mobileSortedHappiness
    } = this.state
    const { destination, source } = result

    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const addRank = (cards: ClientCardObj[]) => {
        cards.forEach((card, index) => {
          cards[index].ranking = index + 1
        })
        return cards
      }
      const reorderCards = (cards: ClientCardObj[]) => {
        return this.reorder(cards, source.index, destination.index)
      }

      switch (this.state.activeCard) {
        case 'Protection':
          this.setState({
            mobileSortedProtection: addRank(
              reorderCards(mobileSortedProtection)
            )
          })
          break
        case 'Commitment':
          this.setState({
            mobileSortedCommitment: addRank(
              reorderCards(mobileSortedCommitment)
            )
          })
          break
        // Happiness
        default:
          this.setState({
            mobileSortedHappiness: addRank(reorderCards(mobileSortedHappiness))
          })
      }
    }
  }

  changeCardType = (type: CardCategoryType, isMobile: boolean) => {
    const {
      protectionCards,
      commitmentCards,
      happinessCards,
      exerciseId,
      dispatch
    } = this.props
    const { activeCard, sortedCards } = this.state
    if (type !== activeCard) {
      if (!isMobile) {
        if (sortedCards.length > 0) {
          let clientId
          sortedCards.forEach((sortedCard, index) => {
            sortedCards[index].ranking = index + 1
            clientId = sortedCard && sortedCard.clientId
          })
          if (exerciseId) {
            dispatch(
              rankClientCardWithExerciseId(clientId, sortedCards, exerciseId)
            )
          } else dispatch(rankClientCard(clientId, exerciseId, sortedCards))
        }
        switch (type) {
          case 'Protection':
            this.setState({
              unsortedCards: protectionCards.filter((card) => !card.ranking),
              sortedCards: protectionCards
                .filter((card) => card.ranking)
                .sort((a, b) => a.ranking - b.ranking),
              activeCard: 'Protection'
            })
            break
          case 'Commitment':
            this.setState({
              unsortedCards: commitmentCards.filter((card) => !card.ranking),
              sortedCards: commitmentCards
                .filter((card) => card.ranking)
                .sort((a, b) => a.ranking - b.ranking),
              activeCard: 'Commitment'
            })
            break
          // Happiness
          default:
            this.setState({
              unsortedCards: happinessCards.filter((card) => !card.ranking),
              sortedCards: happinessCards
                .filter((card) => card.ranking)
                .sort((a, b) => a.ranking - b.ranking),
              activeCard: 'Happiness'
            })
        }
      } else {
        let clientId
        const addRank = (cards: ClientCardObj[]) => {
          cards.forEach((card, index) => {
            cards[index].ranking = index + 1
            clientId = card && card.clientId
          })
          return cards
        }
        switch (type) {
          case 'Protection':
            this.setState({
              activeCard: 'Protection',
              mobileSortedProtection: addRank(protectionCards),
              clientId: clientId
            })
            break
          case 'Commitment':
            this.setState({
              activeCard: 'Commitment',
              mobileSortedCommitment: addRank(commitmentCards),
              clientId: clientId
            })
            break
          // case 'Happiness':
          default:
            this.setState({
              activeCard: 'Happiness',
              mobileSortedHappiness: addRank(happinessCards),
              clientId: clientId
            })
        }
      }
    }
  }

  toggleShowMobileCardTable = () => {
    this.setState({ showMobileCardTable: !this.state.showMobileCardTable })
  }

  toggleShowInstructionsModal = () => {
    this.props.dispatch(showPickFifteenInstructions(false))
  }

  cardsSorted = () => {
    const { protectionCards, commitmentCards, happinessCards } = this.props

    const protectionCardsSorted =
      protectionCards.filter((card) => card.ranking).length === 5
    const happinessCardsSorted =
      happinessCards.filter((card) => card.ranking).length === 5
    const commitmentCardsSorted =
      commitmentCards.filter((card) => card.ranking).length === 5

    const allDeskSorted =
      protectionCardsSorted && happinessCardsSorted && commitmentCardsSorted

    if (allDeskSorted) {
      return true
    }
    return false
  }

  nextPage = async () => {
    const { dispatch, exerciseId } = this.props
    const { sortedCards } = this.state
    if (sortedCards.length === 5) {
      const clientId = sortedCards
        .filter((sortedCard) => sortedCard.clientId)
        .map((sortedCard) => sortedCard.clientId)[0]
      if (exerciseId) {
        dispatch(
          rankClientCardWithExerciseId(clientId, sortedCards, exerciseId)
        )
      } else {
        dispatch(rankClientCard(clientId, exerciseId, sortedCards))
      }
    }
    if (this.cardsSorted()) {
      if (exerciseId) {
        history.push({
          pathname: '/pick/five',
          search: `?exerciseId=${exerciseId}`
        })
      } else {
        history.push('/pick/five')
      }
    }
  }

  goBack = () => {
    history.push('/begin')
  }

  nextButtonProceed = () => {
    const { activeCard, finishedCards } = this.state
    const decksSorted =
      finishedCards.Protection &&
      finishedCards.Happiness &&
      finishedCards.Commitment

    if (activeCard || decksSorted) {
      return true
    }
    return false
  }

  tileHeader = () => {
    return (
      <div className='personal-priorities__header'>
        <Tooltip
          message='Please prioritize all three stacks before moving to the next step.'
          width={180}
          position='bottom'
          multiLine={true}
          hideTooltip={this.cardsSorted() ? true : false}>
          <Button
            type={this.cardsSorted() ? null : 'disabled'}
            onClick={this.nextPage}>
            Next
          </Button>
        </Tooltip>
      </div>
    )
  }

  desktopLayout = () => {
    const { activeCard, unsortedCards } = this.state
    const { protectionCards, commitmentCards, happinessCards } = this.props
    return (
      <Fragment>
        <div className='fifteen-card-pick-desktop__content'>
          <AppHeader
            progressBarStyles={{ width: '80%' }}
            middleHeader={
              <PageHeading sectionNumber='1' text='Personal Priorities' />
            }
          />
          <Tile
            tileHeader={this.tileHeader()}
            tileStyle={{ marginTop: '30px' }}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <FifteenCardTable
                layout='desktop'
                cards={unsortedCards}
                activeCard={activeCard}
                protectionCards={protectionCards}
                commitmentCards={commitmentCards}
                happinessCards={happinessCards}
              />
              <FiveCardArranger cards={this.state.sortedCards} />
            </DragDropContext>
            <FifteenCardHolder
              layout='desktop'
              activeCard={activeCard}
              changeCardType={this.changeCardType}
              protectionCards={protectionCards}
              commitmentCards={commitmentCards}
              happinessCards={happinessCards}
            />
          </Tile>
        </div>
        <Disclosure style={{ maxWidth: 960 }} />
      </Fragment>
    )
  }

  mobileNextPage = () => {
    const { exerciseId, dispatch } = this.props
    const {
      activeCard,
      finishedCards,
      mobileSortedProtection,
      mobileSortedCommitment,
      mobileSortedHappiness,
      clientId
    } = this.state
    const protectionSorted = finishedCards.Protection
    const happinessSorted = finishedCards.Happiness
    const commitmentSorted = finishedCards.Commitment
    const decksNotSorted =
      !protectionSorted || !happinessSorted || !commitmentSorted

    if (activeCard && decksNotSorted) {
      this.setState({
        activeCard: null,
        finishedCards: { ...finishedCards, [activeCard]: true }
      })

      const sortedMobileCards = [
        ...mobileSortedProtection,
        ...mobileSortedCommitment,
        ...mobileSortedHappiness
      ]
      if (exerciseId) {
        dispatch(
          rankClientCardWithExerciseId(clientId, sortedMobileCards, exerciseId)
        )
      } else {
        this.props.dispatch(
          rankClientCard(clientId, exerciseId, sortedMobileCards)
        )
      }
    } else if (!activeCard) {
      // Check if all cards have a ranking to activate the "Next" button
      this.cardsSorted()
      history.push('/pick/five')
    } else {
      this.setState({
        activeCard: null
      })
    }
  }

  mobileLayout = () => {
    const {
      activeCard,
      finishedCards,
      mobileSortedHappiness,
      mobileSortedProtection,
      mobileSortedCommitment
    } = this.state
    const { protectionCards, commitmentCards, happinessCards } = this.props

    let selectedCards = []
    switch (this.state.activeCard) {
      case 'Protection':
        selectedCards = mobileSortedProtection
        break
      case 'Happiness':
        selectedCards = mobileSortedHappiness
        break
      default:
        selectedCards = mobileSortedCommitment
    }

    return (
      <Fragment>
        <AppHeader progressBarStyles={{ width: '80%' }} />
        <FifteenCardHolder
          layout='mobile'
          changeCardType={this.changeCardType}
          activeCard={activeCard}
          finishedCards={finishedCards}
          protectionCards={protectionCards}
          commitmentCards={commitmentCards}
          happinessCards={happinessCards}
        />
        {activeCard && (
          <DragDropContext onDragEnd={this.onDragEndMobile}>
            <FifteenCardTable layout='mobile' cards={selectedCards} />
          </DragDropContext>
        )}
      </Fragment>
    )
  }

  render() {
    const { exerciseInstructionsModal } = this.props

    return (
      <Fragment>
        <div className='personal-priorities__w'>
          <Mobile>
            {this.mobileLayout()}
            <MobileAppHeader
              nextButtonProceed={this.nextButtonProceed()}
              nextButtonOnClick={this.mobileNextPage}
              backButtonOnClick={this.goBack}
              backButtonRequired={false}
            />
          </Mobile>
          <Desktop>{this.desktopLayout()}</Desktop>
        </div>

        {exerciseInstructionsModal.showFifteenCardPick ? (
          <Fragment>
            <Desktop>
              <FifteenCardPickInstructions
                onDismiss={this.toggleShowInstructionsModal}
              />
            </Desktop>
            <Mobile>
              <FifteenCardPickMobileInstructions
                onDismiss={this.toggleShowInstructionsModal}
              />
            </Mobile>
          </Fragment>
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  const clientCards = store.clientCard
  return {
    exerciseInstructionsModal: store.exerciseInstructionsModal,
    protectionCards: Object.keys(clientCards)
      .filter((id) => clientCards[id].rankedWithin === 'Protection')
      .map((id) => clientCards[id]),
    commitmentCards: Object.keys(clientCards)
      .filter((id) => clientCards[id].rankedWithin === 'Commitment')
      .map((id) => clientCards[id]),
    happinessCards: Object.keys(clientCards)
      .filter((id) => clientCards[id].rankedWithin === 'Happiness')
      .map((id) => clientCards[id]),
    exerciseId: store.auth.exerciseId
  }
}

export default connect(mapStateToProps)(FifteenCardPick)
