import { createBrowserHistory } from 'history'
import {
  applyMiddleware,
  legacy_createStore as createStore,
  StoreEnhancer
} from 'redux'

import { routerMiddleware } from 'connected-react-router'
import reducers, { GlobalState } from './reducers'
import { Dispatch, AnyAction } from 'redux'
import { authentication } from 'middleware/authentication'
import { composeWithDevTools } from 'redux-devtools-extension'
import PromiseMiddleware from 'redux-promise-middleware'
import ReduxThunk from 'redux-thunk'
import navigationToTop from './middleware/navigationToTop'
import { maintenanceRedirect } from 'middleware/maintenanceRedirect'

export const history = createBrowserHistory()
const router = routerMiddleware(history)

const middleware: StoreEnhancer = composeWithDevTools(
  applyMiddleware(
    PromiseMiddleware(),
    router,
    ReduxThunk,
    maintenanceRedirect,
    navigationToTop,
    authentication
  )
)

export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = Dispatch<AnyAction, GlobalState>
export const store = createStore(reducers(history), middleware)
// preloaded state for tests
export const setupStore = (preloadedState?: Partial<GlobalState>) => {
  return createStore(reducers(history), preloadedState as any, middleware)
}
