import { Tile } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import React, { Component, ReactNode, CSSProperties } from 'react'

export interface TileBlockProps {
  children: ReactNode
  tileHeader: ReactNode
  tileStyle?: CSSProperties
  contentStyle?: CSSProperties
}
class TileBlock extends Component<TileBlockProps> {
  render() {
    const { children, tileHeader, tileStyle, contentStyle } = this.props
    const outerStyle = Object.assign(
      {
        maxWidth: '960px',
        margin: '0 auto 32px'
      },
      tileStyle
    )
    const innerStyle = Object.assign(
      {
        height: '740px',
        backgroundColor: 'white',
        overflow: 'hidden'
      },
      contentStyle
    )
    return (
      <Tile
        tileStyle={outerStyle}
        contentStyle={innerStyle}
        header={tileHeader}>
        {children}
      </Tile>
    )
  }
}

export default TileBlock
