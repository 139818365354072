import Api from 'helpers/api'
import { ClientCardObj, ClientCardPickFiveObj } from 'objects/clientCard'

export const RANK_CLIENT_CARDS = 'RANK_CLIENT_CARDS'
export const SELECT_TOP_FIVE_CARDS = 'SELECT_TOP_FIVE_CARDS'
export const GET_CLIENT_CARDS = 'GET_CLIENT_CARDS'

export const getClientCards = () => {
  const api = Api.getInstance()
  return {
    type: GET_CLIENT_CARDS,
    payload: api.get('/honestconversation/clientcards').then((response) => {
      return response
    })
  }
}

export const getClientCardsWithExerciseId = (exerciseId: string) => {
  const api = Api.getInstance()
  return {
    type: GET_CLIENT_CARDS,
    payload: api
      .get(`/honestconversation/exercise/${exerciseId}/clientcards`)
      .then((response) => response)
  }
}

export const selectTopFiveCards = (
  cardsArray: ClientCardPickFiveObj[],
  exerciseId: string
) => {
  const api = Api.getInstance()
  return {
    type: `${SELECT_TOP_FIVE_CARDS}`,
    payload: api.post(
      `/honestconversation/exercise/${exerciseId}/clientcards`,
      cardsArray
    )
  }
}

export const selectTopFiveCardsWithExerciseId = (
  cardsArray: ClientCardPickFiveObj[],
  exerciseId: string
) => {
  const api = Api.getInstance()
  return {
    type: `${SELECT_TOP_FIVE_CARDS}`,
    payload: api.post(
      `/honestconversation/exercise/${exerciseId}/clientcards`,
      cardsArray
    )
  }
}

export const rankClientCard = (
  clientId: string,
  exerciseId: string,
  cards: ClientCardObj[] | ClientCardPickFiveObj[]
) => {
  const api = Api.getInstance()
  const requestBody = {
    clientId: clientId,
    clientCards: cards
  }
  return {
    type: `${RANK_CLIENT_CARDS}`,
    payload: api
      .put(
        `/honestconversation/exercise/${exerciseId}/clientcards`,
        requestBody
      )
      .then((response) => {
        response.data = cards
        return response
      })
  }
}

export const rankClientCardWithExerciseId = (
  clientId: string,
  cards: ClientCardObj[] | ClientCardPickFiveObj[],
  exerciseId: string
) => {
  const api = Api.getInstance()
  const requestBody = {
    clientId: clientId,
    clientCards: cards
  }
  return {
    type: `${RANK_CLIENT_CARDS}`,
    payload: api
      .put(
        `/honestconversation/exercise/${exerciseId}/clientcards`,
        requestBody
      )
      .then((response) => {
        response.data = cards
        return response
      })
  }
}

export const addOrEditCardFiveRankingWithExerciseId = (
  clientId: string,
  cards: ClientCardPickFiveObj[],
  exerciseId: string
) => {
  const newCards = cards.filter((card) => !card.id)
  if (newCards.length > 0) {
    return selectTopFiveCardsWithExerciseId(cards, exerciseId)
  }
  return {
    type: RANK_CLIENT_CARDS,
    payload: rankClientCardWithExerciseId(clientId, cards, exerciseId)
      .payload.then((response) => response)
      .catch((error) => {
        if (error.response.status === 400) {
          return selectTopFiveCardsWithExerciseId(
            cards,
            exerciseId
          ).payload.then((createResponse) => createResponse)
        } else {
          return Promise.reject()
        }
      })
  }
}

export const addOrEditCardFiveRanking = (
  clientId: string,
  exerciseId: string,
  cards: ClientCardPickFiveObj[]
) => {
  return {
    type: RANK_CLIENT_CARDS,
    payload: rankClientCard(clientId, exerciseId, cards)
      .payload.then((response) => response)
      .catch((error) => {
        if (error.response.status === 400) {
          return selectTopFiveCards(cards, exerciseId).payload.then(
            (createResponse) => createResponse
          )
        } else {
          return Promise.reject()
        }
      })
  }
}
