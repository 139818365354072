import React from 'react'
import InstructionsModal from 'components/InstructionsModal'
import Pick5Mobile from 'assets/images/five_card_pick/mobiletutorial-pick5.gif'
import Pick5Still from 'assets/images/five_card_pick/tutorial-02-step-1-mobile_still.png'

export interface FiveCardPickInstructionsProps {
  onDismiss(): void
}
class FiveCardPickInstructions extends React.Component<
  FiveCardPickInstructionsProps
> {
  render() {
    const { onDismiss } = this.props
    const instructions = {
      mobile: Pick5Mobile,
      still: Pick5Still
    }
    return (
      <InstructionsModal
        onDismiss={onDismiss}
        instructions={instructions}
        instructionGif='five'
        web={false}
      />
    )
  }
}

export default FiveCardPickInstructions
