import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { ClientCardObj } from 'objects/clientCard'
import React, { Component } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'

export interface FifteenCardTableProps {
  cards: ClientCardObj[]
  layout: 'mobile' | 'desktop'
  activeCard?: string
  happinessCards?: ClientCardObj[]
  commitmentCards?: ClientCardObj[]
  protectionCards?: ClientCardObj[]
}

class FifteenCardTable extends Component<FifteenCardTableProps> {
  render() {
    const {
      layout,
      cards,
      activeCard,
      happinessCards,
      commitmentCards,
      protectionCards
    } = this.props
    return (
      <div className='fifteen-card-table'>
        <Droppable
          droppableId='unsortedCards'
          direction={layout === 'desktop' ? 'horizontal' : 'vertical'}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className='fifteen-card-table__w'>
                {cards?.length ? (
                  cards.map((card, index) => {
                    const clientCard = card.card
                    return (
                      <Draggable
                        key={card.id}
                        draggableId={card.id}
                        index={index}>
                        {(providedDraggable) => (
                          <div
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                            style={{
                              position: 'relative',
                              zIndex: cards.length - index,
                              ...providedDraggable.draggableProps.style
                            }}>
                            <Card
                              category={clientCard.category}
                              title={clientCard.title}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  })
                ) : happinessCards?.length &&
                  commitmentCards?.length &&
                  protectionCards?.length &&
                  !activeCard ? (
                  <p className='fifteen-card-table__text'>
                    Please start by selecting a deck below.
                  </p>
                ) : null}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    )
  }
}

export default FifteenCardTable
