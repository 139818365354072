export const SHOW_INSTRUCTIONS_FIFTEEN_CARDS = 'SHOW_INSTRUCTIONS_FIFTEEN_CARDS'
export const SHOW_INSTRUCTIONS_FIVE_CARDS = 'SHOW_INSTRUCTIONS_FIVE_CARDS'

export const showPickFifteenInstructions = (show: boolean) => {
  return {
    type: SHOW_INSTRUCTIONS_FIFTEEN_CARDS,
    payload: show
  }
}

export const showPickFiveInstructions = (show: boolean) => {
  return {
    type: SHOW_INSTRUCTIONS_FIVE_CARDS,
    payload: show
  }
}
