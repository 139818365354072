export const SET_EXERCISE_ID_AND_CLIENT_ID = 'SET_EXERCISE_ID_AND_CLIENT_ID'

export const setExerciseIdAndClientId = (
  exerciseId: string,
  clientId: string
) => {
  return {
    type: SET_EXERCISE_ID_AND_CLIENT_ID,
    payload: {
      exerciseId,
      clientId
    }
  }
}
