import { Card } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { ClientCardObj } from 'objects/clientCard'
import React, { Component, Fragment } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'

interface FiveCardArrangerProps {
  cards: ClientCardObj[]
}

class FiveCardArranger extends Component<FiveCardArrangerProps> {
  render() {
    const { cards } = this.props
    const cardPlaceHolder = (cardLength: number) => {
      if (cards.length === 0 && cards.length === cardLength) {
        return 'five-card-arranger__card five-card-arranger__card--active'
      } else if (cards.length > 0 && cards.length === cardLength) {
        return 'five-card-arranger__card five-card-arranger__card--margin-top five-card-arranger__card--active'
      } else {
        return 'five-card-arranger__card'
      }
    }

    return (
      <div className='five-card-arranger__w'>
        <Droppable droppableId='sortedCards'>
          {(provided) => (
            <Fragment>
              <div
                ref={provided.innerRef}
                style={{
                  position: 'absolute',
                  height: '500px',
                  width: '200px'
                }}>
                {this.props.cards.map((card, index) => {
                  const clientCard = card?.card
                  if (clientCard) {
                    return (
                      <Draggable
                        key={`${card?.id}-${clientCard?.category}`}
                        draggableId={`${card?.id}-${clientCard?.category}`}
                        index={index}>
                        {(providedDraggable) => (
                          <div
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}>
                            <Card
                              cardStyle={{
                                marginBottom: '-30px'
                              }}
                              category={clientCard?.category}
                              title={clientCard?.title}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  } else {
                    return null
                  }
                })}
                {provided.placeholder}
              </div>
              <div className={cardPlaceHolder(0)}>1</div>
              <div className={cardPlaceHolder(1)}>2</div>
              <div className={cardPlaceHolder(2)}>3</div>
              <div className={cardPlaceHolder(3)}>4</div>
              <div className={cardPlaceHolder(4)}>5</div>
            </Fragment>
          )}
        </Droppable>
      </div>
    )
  }
}

export default FiveCardArranger
